$base-primary: #2979ff;
$base-primary-hover: #0d47a1;
$base-accent: #757575;
$base-secondary: white;
$calendarPlannerIconColor: #28ABE3;
$assetIconColor: #563d7c;
$dashboardIconColor: #cda434;
$monitoringIconColor: #4c00ff;
$settingsIconColor: #c10000;
$ticketIconColor: orange;
$consultationsIconColor: #366a4b;
$itemsIconColor: #831843;
$ncIconColor: #d45959;
$default-green: #03c100;
$default-red: #e60000;
$defaultDarkGray: #4e5263;
$float-label-color: rgba(0, 0, 0, 0.54);
$icon-color: rgba(0, 0, 0, 0.6);

$gatewayStateNoneBgBolor: #FFFFFF;
$gatewayStateOnlineBgBolor: #009900;
$gatewayStateOfflineBgBolor: #EC7063;

$grid-breakpoints: (
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);


@mixin smartphone-xs {
  @media (min-width: 320px) {
    @content
  }
}

@mixin smartphone-sm {
  @media (min-width: 375px) {
    @content
  }
}

@mixin smartphone {
  @media (min-width: 576px) {
    @content
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin custom-icon-wrapper {
  font-family: "custom-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  @content;
}

.list-box {
  display: flex;
  width: calc(100% - 10px);
  padding: 10px;
  margin: 10px 5px;
  box-shadow: 1px 1px 3px #888888;
  cursor: pointer;
  flex-direction: column;
  word-break: break-word;
  border-radius: .5rem;

  &:hover {
    border: 3px solid #2979ff;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0.6em;
  }

  @include tablet() {
    & {
      flex-direction: row;
    }
  }

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    cursor: pointer;
  }

  .main-child {
    display: flex;
    flex-direction: column;
    margin-right: 15px;

    &.col-reverse {
      flex-direction: column-reverse;
    }

    &.f-0 {
      flex: 0;
    }

    &.f-01 {
      flex: 0.1;
    }

    &.f-02 {
      flex: 0.2;
    }

    &.f-04 {
      flex: 0.4;
    }

    &.f-06 {
      flex: 0.6;
    }

    &.f-08 {
      flex: 0.8;
    }

    &.f-09 {
      flex: 0.9;
    }

    &.f-1 {
      flex: 1;
    }

    .child-row {
      display: flex;
      margin: 3px;
      height: 100%;
      width: 100%;
      flex-direction: column;
      min-width: 17px;

      .e-dropdownlist {
        flex-direction: row;
      }
    }
  }

  .btn-trash {
    display: flex;
    flex-direction: row;
    padding: unset;
    margin: unset;

    div {
      justify-content: center;
      align-items: flex-end;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 34px;
      width: 38px;
      height: 34px;
      cursor: pointer;
      font-size: 1.2rem;
      background-color: unset;
      margin: 0;
      padding: 0;

      @include tablet() {
        & {
          margin: 0 0.5em;
          padding: 0 16px;
        }
      }

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
        color: inherit;
      }

      &:not([disabled])[color="warn"]:hover {
        color: red;
      }
    }
  }

  .available-icon {
    fa-icon {
      font-size: 1.2rem;
      color: $default-green;
      margin-left: 3px;
    }
  }
}
