.p-select-clear-icon {
  margin-top: -.5rem;
}

p-select.p-component.borderless {
  border: unset;
  height: 2rem;
  outline: unset;
  box-shadow: unset;
}

.p-select-option {
  &:hover {
    background: var(--p-select-option-focus-background);
    color: var(--p-select-option-focus-color);
  }
}