@use "utility" as utils;

.hidden {
  display: none;
}

.full-width-height {
  height: 100%;
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.page-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noselect-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.green {
  color: #03c100;
}

.red {
  color: #e60000;
}

.light-orange {
  color: #ffbb33;
}

.light-blue {
  color: #00a5e0;
}

.tag-application-blue {
  color: utils.$base-primary
}

.dashboard-loader {
  height: 94%;
  top: 6%;
}

.my-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  &.default-bg {
    background-color: #eee;
  }
}

.text-label {
  margin-bottom: 10px;
  font-size: 12px;
  -webkit-text-fill-color: rgb(51, 65, 85) !important;
  color: rgb(51, 65, 85) !important;
  font-weight: bold !important;
}

.spinner-circle {
  -webkit-animation: rotation 1.35s linear infinite;
  animation: rotation 1.35s linear infinite;
  margin-top: 3px;
  .circle {
    stroke-dasharray: 180;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-animation: turn 1.35s ease-in-out infinite;
    animation: turn 1.35s ease-in-out infinite;
    stroke: utils.$base-primary
  }
}

.iot-gateway-loader,
.settings-loader {
  background-color: white !important;
}

.btn-toggle-drawer {
  min-width: 34px !important;
  height: 34px;
}

.header-bar {
  min-height: 60px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-content,
.modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 640px;
  }
}

.modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible;
}

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #dbdbdb;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: 0.5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
}

.modal-box {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  &.show {
    display: block;
  }
  .modal-card{
    margin: 0 auto;
    /*border-radius: 0.5rem;*/
    .header-title{
      font-size: 22px;
      font-weight: 500;
    }
    .close-edit {
      cursor: pointer;
    }
    .modal-card-head,
    .modal-card-foot {
      border-radius: 0;
      align-items: unset;
      background-color: white;
      height: 80px;
    }
    .modal-card-head{
      .modal-card-title {
        width: 98%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        //margin-left: 15px;
      }
    }
    .modal-card-foot {
      button {
        margin-left: 0.5em;
      }
    }
    .modal-card-body{
      max-height: 60vh;
    }
    .modal-card-foot{
      justify-content: flex-end;
    }
  }
}

.line-separator {
  height: 1px;
  margin: 25px 0;
  background: utils.$base-primary;
}

.line-separator-label {
  display: flex;
  font-weight: 500;
  margin: .5rem 0 .5rem 0;
  color: utils.$base-primary;

  &:before {
    border-bottom: solid 1px utils.$base-primary;
    box-sizing: border-box;
    content: ' ';
    display: block;
    height: 8px;
    margin-top: 3px;
    margin-right: 4px;
    min-width: 9px;
    left: -1px;
    border-left: solid 1px transparent;
    position: relative;
  }

  &:after {
    border-bottom: solid 1px utils.$base-primary;
    content: ' ';
    display: block;
    height: 8px;
    margin-top: 3px;
    min-width: 9px;
    position: relative;
    border-right: solid 1px transparent;
    flex-grow: 1;
    left: 2px;
    margin-left: 4px;
  }
}

.no-border-input {
  height: 38px;
  padding: 5px 10px;
  &.disabled {
    background-color: #eee;
    -webkit-text-fill-color: unset;
    color: #555;
  }
  div.e-input-group {
    border: 0 !important;
  }
  span {
    border: none !important;
    &:before,
    &:after {
      background: unset !important;
    }
  }
  span.e-float-line {
    display: none !important;
  }
}

.job-detail-loader {
  background: white !important;
  height: calc(100% - 100px);
  top: 99px;
}

.outcome {
  flex-direction: column;
  min-height: 50px;
  padding: 5px;

  div {
    padding: 1px 10px;
  }

  @include utils.tablet() {
    & {
      flex-direction: row;
    }
  }
}

.cta-bottom-btn {
  padding-left: 0;
  margin: 0 -10px;
  box-shadow: 0 0 20px -11px;
  div > button {
    margin-left: 3px !important;
  }
}

@include utils.smartphone-sm() {
  .cta-bottom-btn {
    /*padding-left: 0.75rem;*/

    div > button {
      margin-left: 0.5em !important;
    }
  }
}


.uploader {
  display: flex;
  width: 100%;
  align-items: end;

  .drop-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 37px;
    border: 1px dashed #c3c3cc;
    border-radius: .5rem;
  }

  .uploader-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    min-width: 34px;
    width: 38px;
    align-self: center;
    margin: 0 0 0 10px;
  }
}

.e-upload.e-control-wrapper {
  display: none;
}

.add-el-tab-btn {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.is-template:not(.e-dropdown-popup) {
  background-color: #f95700ff !important;

  .modal-card-title,
  .job-actions,
  .e-btn-icon,
  .close-edit {
    color: white !important;
  }
}

.is-schedule:not(.e-dropdown-popup) {
  background-color: #2bae66ff !important;

  .modal-card-title,
  .job.actions,
  .e-btn-icon,
  .close-edit {
    color: #fcf6f5ff !important;
  }
}

.padding-right5{
  padding-right: 5px;
}

.spinner-dropdowns {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
}

.switch {
  height: max-content;

  &,
  &-no-height,
  &-no-height-date {
    padding: 11px 1px 8px 1px;
    display:flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    & > [ng-reflect-placeholder] {
      margin-top: 0.5rem;
    }
  }
}

@include utils.smartphone-sm() {
  .switch,
  .switch-no-height,
  .switch.no-height-date {
    //height: 38px;
    padding: 0 10px;
    align-items: center;
    flex-direction: row;
    margin-top: 0.35rem;
  }
}


.max-content {
  width: max-content;
  height: max-content;

  &-width {
    width: max-content;
  }

  &-height {
    height: max-content;
  }
}

.monitored-variable {
  flex-direction: column;
}

.fill-available {
  width: min(100%)!important;
}

.ant-select {

  &-selector{
    height: 38px;
    padding: 4px 6px !important;
  }

  &-multiple &-selection-search {
    margin-bottom: 5px;
  }

  &-multiple &-selection-item {
    border-top: unset;
    border-bottom: unset;
    border-left: unset;
    border-right: 1px solid rgba(0, 0, 0, 0.24);
    background: transparent;
    border-radius: unset;
  }

  &-close-icon {
    //content: '\e208';
    //font-family: 'e-icons';
    font-size: 16px;
  }

  &-dropdown {
    .ant-select-item {
      &:hover {
        color: #2979ff;
        background: transparent;
      }

      .ant-select-item-option-active {
        &,
        &:hover{
          font-weight: normal;
          background: lightgrey;
        }
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: #2979ff;
      font-weight: normal;
      background: #eee;

      &:hover {
        background: #eee;
      }
    }

    .ant-select-item-option-selected {
      .ant-select-item-option-state {
        display: none;
      }
    }
  }
}

.syncfusion-clear-icon {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: -2px;
  margin-left: -10px;

  &:hover {
    color: black;
  }
}

.div-label {
  @apply tw-font-bold tw-mb-1;
}

.custom-table {
  @apply tw-w-full tw-max-w-full tw-text-left tw-border-separate tw-border tw-border-slate-200 tw-rounded-lg;
  thead {
    tr {
      &:nth-child(1) {
        @apply tw-border-b-0;
      }
      &:nth-child(2) {
        @apply tw-border-t-0;
      }
    }
  }
  tr {
    td, th {
      @apply tw-p-2 tw-border-l tw-border-t tw-border-slate-200;

      &:first-child {
        @apply tw-border-l-0;
      }

      &:nth-child(1) {
        @apply tw-w-[35%];
      }
      &:not(:nth-child(1)) {
        @apply tw-border-solid tw-border-l tw-border-gray-200 tw-w-[10%] tw-text-center;
      }
    }
    th {
      @apply tw-border-t-0;
    }
  }
}

.result-job-text {
  &.Positive {
    @apply tw-text-nc-resolved;
  }
  &.Negative {
    @apply tw-text-nc-toBeResolved;
  }
  &.NotExecutable {
    @apply tw-text-gray-500;
  }
}

.nc-state {
  &-border {
    &-ToBeResolved {
      @apply tw-border-nc-toBeResolved;
    }
    &-InResolution {
      @apply tw-border-nc-inResolution;
    }
    &-Resolved {
      @apply tw-border-nc-resolved;
    }
  }
  &-bg {
    &-ToBeResolved {
      @apply tw-bg-nc-toBeResolved;
    }
    &-InResolution {
      @apply tw-bg-nc-inResolution;
    }
    &-Resolved {
      @apply tw-bg-nc-resolved;
    }
  }
  &-shadow {
    &-ToBeResolved {
      @apply tw-shadow-nc-toBeResolved;
    }
    &-InResolution {
      @apply tw-shadow-nc-inResolution;
    }
    &-Resolved {
      @apply tw-shadow-nc-resolved;
    }
  }
}

.review-grade {
  &-LOW {
    @apply tw-text-grade-LOW;
  }
  &-POOR {
    @apply tw-text-grade-POOR;
  }
  &-SUFFICIENT {
    @apply tw-text-grade-SUFFICIENT;
  }
  &-GOOD {
    @apply tw-text-grade-GOOD;
  }
  &-EXCELLENT {
    @apply tw-text-grade-EXCELLENT;
  }
}

.btn-ico {
  @apply tw-border-transparent tw-shadow-none disabled:tw-cursor-not-allowed
         hover:tw-shadow-none disabled:tw-opacity-75 disabled:hover:tw-text-inherit
}

.animate-spin {
  svg {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
